import styled, { keyframes } from 'styled-components';
import { themeGet } from 'styled-system';

const shake = keyframes`
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  50% {
    transform: translateX(7px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;


export const ErrorWrapper = styled.div`
  background-color: ${themeGet('colors.paletteYellow')};
  padding: 100px 15px 50px 15px;
  display: flex;
  align-items: center;
`;

export const ErrorContent = styled.div`
  width: 450px;
  max-width: 100%;
  padding: 0px 15px;
  margin: 0 auto;
  
  .image-wrapper {
    @media (max-width: 1480px) {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;

  @media only screen and (max-width: 767px) {
    margin-top: 25px;
    margin-bottom: 54px;
  }

  .reusecore__button {
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    &:first-child {
      margin-right: 20px;
      &:hover {
        opacity: 0.95;
      }
    }

    &:hover {
      .btn-icon {
        animation: ${shake} 1s infinite;
      }
    }
  }
`;


