import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Image from 'reusecore/src/elements/Image';
import ErrorImage from 'common/src/assets/image/interior/error/error.svg';
import { ErrorWrapper, ErrorContent } from './error.style';

const ErrorSec = ({ imageWrapper, title, text}) => {
  return (
    <ErrorWrapper>
      <ErrorContent>
        <Box {...imageWrapper} className="image-wrapper">
          <Image src={ErrorImage} alt="404" />
        </Box> 
        <Heading {...title} content="Página não encontrada!" />
        <Text
          {...text}
          content="Parece que a página que você está tentando acessar não existe. Por favor, dá uma olhada na URL e tente a sorte novamente."
        />
      </ErrorContent>
    </ErrorWrapper>
  );
};

ErrorSec.propTypes = {
  imageWrapper: PropTypes.object,
};

ErrorSec.defaultProps = {
  imageWrapper: {
    width: '200px',
    mt: '40px',
    mb: '40px',
  },
  title: {
  },
  text: {
    color: '#000000',
  },
  homeButton: {
    type: 'button',
    color: '#000000',
  },
};

export default ErrorSec;
